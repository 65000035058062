import Popover from '@mui/material/Popover';
import { useState } from 'react';

import { useBaseStore } from '@/store/base';
import { useUserStore } from '@/store/user';

import Arrow from './img/arrow.svg';
import More from './img/more.svg';
import Report from './img/report.svg';

import style from './moreOption.module.scss';

function MoreOption({ className }: { className?: string }) {
    const isLogin = useUserStore.use.isLogin();
    const setLoginPopup = useBaseStore.use.setLoginPopup();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleReport = () => {
        if (!isLogin) {
            setLoginPopup();
            return;
        }
        handleClose();
        $crisp.push(['set', 'session:event', ['click_report']]);
        $crisp.push(['do', 'chat:open']);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <More
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
                className={`${style.moreIcon} ${className}`}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                className={style.moreOption}
            >
                <div className={style.optionLine}>
                    <Report />
                    <div className={style.text} onClick={handleReport}>
                        檢舉
                    </div>
                    <Arrow />
                </div>
            </Popover>
        </>
    );
}

export default MoreOption;
