'use client';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Image from 'next/image';
import { useParams } from 'next/navigation';
import { useState, MouseEvent } from 'react';

import {
    postLikeMedia,
    cancelLikeMedia,
    postLikeDrama,
    cancelLikeDrama
} from '@/api/couple/cpInfo';
import CpInfoCard from '@/components/cp/cpInfoCard/cpInfoCard';
import MediaWithAuthHeader from '@/components/mediaWithAuthHeader/mediaWithAuthHeader';
import MoreOption from '@/features/moreOption/moreOption';
import { useUserInfoCompletedHook } from '@/features/userInfoCompleted/userInfoCompleted';
import { useValidatePhoneHook } from '@/features/validatePhone/validatePhone';
import { timestampToMMDD } from '@/lib/timeHandle';
import { useBaseStore } from '@/store/base';
import { useChatStore } from '@/store/chat';
import { useNotifyStore } from '@/store/notify';
import { useUserStore } from '@/store/user';
import {
    cpCreateImageListType,
    CpPornAlbumInfo as CpCreatePornAlbumInfo
} from '@/types/cpCreateType';
import {
    CpInfoType,
    CpVideoType,
    CpImageType,
    CpPornAlbumInfo,
    CpPornAlbumEpisodeVideo,
    CpPornAlbumTrailerVideo
} from '@/types/cpType';

import ChatIcon from './img/chat.svg';
import CloseIcon from './img/close.svg';
import DeleteIcon from './img/delete.svg';
import DiamondIcon from './img/diamond.svg';
import FavoriteIcon from './img/favorite.svg';
import GiftsIcon from './img/gifts.svg';
import LikeInfoIcon from './img/likeInfo.svg';
import LockIcon from './img/lock.svg';
import MoreIcon from './img/more.svg';
import ShareIcon from './img/share.svg';
import UnfavoriteIcon from './img/unFavorite.svg';
import UnLockIcon from './img/unLock.svg';
import VideoLibraryIcon from './img/videoLibrary.svg';
import ViewIcon from './img/view.svg';

import style from './cpMediaInfoCard.module.scss';

type noTrailerType = CpVideoType | CpImageType | CpPornAlbumEpisodeVideo;

type InfoType = CpVideoType | CpImageType | CpPornAlbumEpisodeVideo | CpPornAlbumTrailerVideo;
type CreateType = cpCreateImageListType;

function CpMediaInfoCard({
    userInfo,
    mediaData,
    albumInfo,
    isVideo = false,
    isAlbum = false,
    isPorn = false,
    isTrailer = false,
    isCreate = false,
    handleUnLock,
    handleDelete,
    onClose
}: {
    userInfo: CpInfoType;
    mediaData: InfoType | CreateType;
    albumInfo?: CpPornAlbumInfo | CpCreatePornAlbumInfo;
    isVideo?: boolean; // 是否為影片
    isAlbum?: boolean; //  是否為影集
    isPorn?: boolean; // 是否為迷片
    isTrailer?: boolean; // 是否為預告片
    isCreate?: boolean; // 是否創作頁
    handleUnLock?: () => void;
    handleDelete?: () => void;
    onClose?: () => void;
}) {
    const params = useParams();
    const setSnackMessage = useNotifyStore.use.setSnackMessage();
    const isLogin = useUserStore.use.isLogin();
    const setLoginPopup = useBaseStore.use.setLoginPopup();
    const setCurrentChat = useChatStore.use.setCurrentChat();
    const switchChat = useChatStore.use.switchChat();
    const [hideView, setHideView] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [likeCount, setLikeCount] = useState(mediaData.favor_count || 0);
    const [isLike, setIsLike] = useState((mediaData as InfoType)?.is_favor || false);
    const open = Boolean(anchorEl);
    const { checkUserInfoCompleted } = useUserInfoCompletedHook();
    const { checkValidatePhone } = useValidatePhoneHook();
    const baseUrl =
        useBaseStore.getState().envParams === 'prod'
            ? process.env.NEXT_PUBLIC_BASE_WEBSITE_URL_PROD
            : process.env.NEXT_PUBLIC_BASE_WEBSITE_URL_DEV;

    const handleUnLockBtn = (event: MouseEvent<HTMLElement>) => {
        if (hideView) return;
        event.stopPropagation();

        if (!isLogin) {
            setLoginPopup();
            return;
        }

        if (checkUserInfoCompleted() || checkValidatePhone()) {
            return;
        }

        if (handleUnLock) {
            handleUnLock();
        }
    };

    const handleDeleteBtn = () => {
        if (hideView) return;

        handleMenuClose();
        if (handleDelete) {
            handleDelete();
        }
    };

    const handleBackBtn = () => {
        if (onClose) {
            onClose();
            return;
        }
        if (hideView) return;
        if (isCreate) {
            if (isPorn) {
                window.location.href = `/cpCreate/p/${params.albumId}`;
            } else {
                window.location.href = `/cpCreate/${isVideo ? 'v' : 'i'}`;
            }
        } else {
            if (isPorn) {
                window.location.href = `/cp/${params.userId}/p`;
            } else {
                window.location.href = `/cp/${params.userId}/${isVideo ? 'v' : 'i'}`;
            }
        }
    };

    const handleToCpPage = () => {
        if (isCreate) {
            if (isPorn) {
                window.location.href = `/cpCreate/p/${params?.albumId || albumInfo?.id}`;
            } else {
                window.location.href = `/cpCreate/${isVideo ? 'v' : 'i'}`;
            }
        } else {
            if (isPorn) {
                window.location.href = `/cp/${params?.userId || userInfo.user_id}/p`;
            } else {
                window.location.href = `/cp/${params?.userId || userInfo.user_id}/${
                    isVideo ? 'v' : 'i'
                }`;
            }
        }
    };

    const shareSite = async () => {
        if (hideView) return;

        const targetUrl = `${baseUrl}${location.pathname}`;

        const shareUrl = isCreate
            ? targetUrl.replace('/cpCreate', `/cp/${userInfo.user_id}`)
            : targetUrl;

        if (typeof navigator.share !== 'undefined') {
            await navigator
                .share({
                    title: 'WHyWHy',
                    url: `${shareUrl}`
                })
                .then(() => {
                    setSnackMessage({
                        message: '分享成功！'
                    });
                })
                .catch(async error => {
                    await navigator.clipboard.writeText(`${shareUrl}`);
                    console.error(error);
                });
        } else {
            await navigator.clipboard.writeText(`${shareUrl}`);
            setSnackMessage({
                message: '已複製分享連結！'
            });
        }

        return null;
    };

    const handleShareBtn = (event: MouseEvent<HTMLElement>) => {
        if (hideView) return;
        event.stopPropagation();
        shareSite();
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleMoreBtn = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (hideView) return;
        setAnchorEl(event.currentTarget);
    };

    const setReplyData = useChatStore.use.setReplyData();
    const setIsOpenSticker = useChatStore.use.setIsOpenSticker();
    const handleChat = (event: React.MouseEvent<HTMLElement>, type?: string) => {
        event.preventDefault();
        if (!isLogin) {
            setLoginPopup();
            return;
        }
        if (checkUserInfoCompleted() || checkValidatePhone()) {
            return;
        }

        if (type) {
            setIsOpenSticker(true);
        }

        let mediaType = 'image';
        if (isVideo) mediaType = 'video';
        if (isAlbum) mediaType = 'episode';
        if (isPorn) mediaType = 'drama';
        if (isTrailer) mediaType = 'trailer';
        setReplyData({
            img: (mediaData as { cover: string }).cover || mediaData.url,
            description: (mediaData as { description: string }).description,
            type: mediaType,
            id: mediaData.id
        });
        setCurrentChat({ ...mediaData, ...{ user_id: userInfo.user_id } });
        switchChat();
    };

    const fetchLike = async () => {
        const params: { userId: string; type: string; id: string; dramaId?: string } = {
            userId: userInfo.user_id,
            type: '',
            id: mediaData.id
        };

        if (isPorn) {
            if (isTrailer) {
                params.type = 'trailer';
                params.dramaId = albumInfo?.id;
            } else {
                params.type = 'episode';
                params.dramaId = albumInfo?.id;
            }
        } else {
            if (isVideo) {
                params.type = 'video';
            } else {
                params.type = 'image';
            }
        }

        const res = isPorn
            ? await postLikeDrama(
                  params as { userId: string; dramaId: string; type: string; id: string }
              )
            : await postLikeMedia(params);

        if (res.status === 200) {
            setLikeCount(count => count + 1);
            setSnackMessage({ message: '已按讚' });
            setIsLike(true);
        }
    };

    const fetchUnLike = async () => {
        const params: { userId: string; type: string; id: string; dramaId?: string } = {
            userId: userInfo.user_id,
            type: '',
            id: mediaData.id
        };

        if (isPorn) {
            if (isTrailer) {
                params.type = 'trailer';
                params.dramaId = albumInfo?.id;
            } else {
                params.type = 'episode';
                params.dramaId = albumInfo?.id;
            }
        } else {
            if (isVideo) {
                params.type = 'video';
            } else {
                params.type = 'image';
            }
        }

        const res = isPorn
            ? await cancelLikeDrama(
                  params as { userId: string; dramaId: string; type: string; id: string }
              )
            : await cancelLikeMedia(params);

        if (res.status === 200) {
            setLikeCount(count => count - 1);
            setSnackMessage({ message: '已取消讚' });
            setIsLike(false);
        }
    };

    const handleLikeClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if (!isLogin) {
            setLoginPopup();
            return;
        }

        if (isLike) {
            fetchUnLike();
        } else {
            fetchLike();
        }
    };

    return (
        <div className={style.operationInterface}>
            <div className={`${style.headerOption}  ${hideView && style.hideView}`}>
                <CloseIcon className={style.backButton} onClick={handleBackBtn} />
                {isPorn && <div className={style.middleText}>{isTrailer ? '預告' : ''}</div>}
                {isCreate ? (
                    <div className={style.moreOption}>
                        <MoreIcon
                            id="moreBtn"
                            className={style.moreButton}
                            onClick={handleMoreBtn}
                        />
                        <Menu
                            className={style.moreMenu}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleMenuClose}
                            MenuListProps={{
                                'aria-labelledby': 'moreBtn-button'
                            }}
                        >
                            <MenuItem className={style.option} onClick={handleDeleteBtn}>
                                刪除 <DeleteIcon />
                            </MenuItem>
                        </Menu>
                    </div>
                ) : (
                    <>
                        <MoreOption className={style.iconBtn} />
                    </>
                )}
            </div>
            <div className={style.viewContainer} onClick={() => setHideView(type => !type)}>
                {isVideo ? (
                    <>
                        {isPorn ? (
                            <>
                                {isTrailer ? (
                                    <>
                                        <MediaWithAuthHeader alt="" src={mediaData.url} isVideo />
                                    </>
                                ) : (
                                    <>
                                        {(mediaData as CpPornAlbumEpisodeVideo).is_public ? (
                                            <MediaWithAuthHeader
                                                alt=""
                                                src={mediaData.url}
                                                isVideo
                                            />
                                        ) : (
                                            <>
                                                {(mediaData as noTrailerType).is_lock ? (
                                                    <Image
                                                        alt=""
                                                        src={
                                                            isVideo
                                                                ? (mediaData as CpVideoType).cover
                                                                : mediaData.url
                                                        }
                                                        fill
                                                        sizes="100%"
                                                        style={{ objectFit: 'contain' }}
                                                        className={style.img}
                                                    />
                                                ) : (
                                                    <MediaWithAuthHeader
                                                        alt=""
                                                        src={mediaData.url}
                                                        isVideo
                                                    />
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                {(mediaData as noTrailerType).is_lock ? (
                                    <Image
                                        alt=""
                                        src={
                                            isVideo
                                                ? (mediaData as CpVideoType).cover
                                                : mediaData.url
                                        }
                                        fill
                                        sizes="100%"
                                        style={{ objectFit: 'contain' }}
                                        className={style.img}
                                    />
                                ) : (
                                    <MediaWithAuthHeader alt="" src={mediaData.url} isVideo />
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {(mediaData as CpVideoType | CpImageType | CpPornAlbumEpisodeVideo)
                            .is_lock ? (
                            <Image
                                alt=""
                                src={isVideo ? (mediaData as CpVideoType).cover : mediaData.url}
                                fill
                                sizes="100%"
                                style={{ objectFit: 'contain' }}
                                className={style.img}
                            />
                        ) : (
                            <MediaWithAuthHeader
                                className={style.img}
                                src={isVideo ? (mediaData as CpVideoType).cover : mediaData.url}
                            />
                        )}
                    </>
                )}

                {isPorn ? (
                    <>
                        {!isTrailer &&
                            !(mediaData as CpPornAlbumEpisodeVideo).is_public &&
                            (mediaData as CpPornAlbumEpisodeVideo).is_lock && (
                                <div className={`${style.mask} ${hideView && style.hideView}`}>
                                    <div
                                        className={style.lockBar}
                                        onClick={event => handleUnLockBtn(event)}
                                    >
                                        <LockIcon />
                                        {albumInfo?.diamond ||
                                            (mediaData as CpVideoType | CpImageType).diamond}{' '}
                                        鑽｜解鎖
                                    </div>
                                </div>
                            )}
                    </>
                ) : (
                    <>
                        {(mediaData as noTrailerType).is_lock && (
                            <div className={`${style.mask} ${hideView && style.hideView}`}>
                                <div
                                    className={style.lockBar}
                                    onClick={event => handleUnLockBtn(event)}
                                >
                                    <LockIcon />
                                    {albumInfo?.diamond ||
                                        (mediaData as CpVideoType | CpImageType).diamond}{' '}
                                    鑽｜解鎖
                                </div>
                            </div>
                        )}
                    </>
                )}
                {typeof (mediaData as InfoType)?.is_favor !== 'undefined' && (
                    <div
                        className={`${style.likeBtn} ${hideView && style.hideView}`}
                        onClick={handleLikeClick}
                    >
                        {isLike ? (
                            <FavoriteIcon className={style.icon} />
                        ) : (
                            <UnfavoriteIcon className={style.icon} />
                        )}
                    </div>
                )}
            </div>
            <div
                className={`${style.footerPlaceholder} ${hideView && style.hideView} ${
                    isVideo && style.isVideo
                }`}
            >
                <div className={`${style.footer}`}>
                    <CpInfoCard
                        src={userInfo.avatar}
                        subText={`@${userInfo.account}`}
                        userName={userInfo.nickname}
                        userId={userInfo.user_id}
                        tag={userInfo.label}
                        linkToCpPage={false}
                        onClick={() => handleToCpPage()}
                    />

                    <div className={style.box}>
                        <div className={style.left}>
                            <div className={style.infoBar}>
                                <div className={style.info}>
                                    <LikeInfoIcon /> {likeCount}
                                </div>
                                <div className={style.info}>
                                    <ViewIcon /> {mediaData.view_count || 0}
                                </div>
                                {isPorn ? (
                                    <>
                                        {!isTrailer &&
                                            !(mediaData as CpPornAlbumEpisodeVideo).is_public && (
                                                <>
                                                    <div className={style.info}>
                                                        <DiamondIcon /> {albumInfo?.diamond}鑽
                                                    </div>
                                                    <div className={style.info}>
                                                        <UnLockIcon /> {mediaData.unlock_count}
                                                    </div>
                                                </>
                                            )}
                                    </>
                                ) : (
                                    <>
                                        {(mediaData as CpVideoType | CpImageType).diamond > 0 && (
                                            <>
                                                <div className={style.info}>
                                                    <DiamondIcon />{' '}
                                                    {albumInfo?.diamond ||
                                                        (mediaData as CpVideoType | CpImageType)
                                                            .diamond}
                                                    鑽
                                                </div>
                                                <div className={style.info}>
                                                    <UnLockIcon /> {mediaData.unlock_count}
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            {(mediaData as CpVideoType | CpImageType)?.description?.length > 0 && (
                                <div className={style.content}>
                                    {(mediaData as CpVideoType | CpImageType)?.description || ''}
                                </div>
                            )}

                            {mediaData.labels?.length > 0 && (
                                <div className={style.tagBar}>
                                    {mediaData.labels.map((label, idx) => (
                                        <div className={style.tag} key={`${label.key}_${idx}`}>
                                            #{label.name}
                                        </div>
                                    ))}
                                </div>
                            )}

                            <div className={style.time}>
                                {timestampToMMDD(mediaData.created_at)}
                            </div>
                        </div>
                        <div className={style.right}>
                            <div className={style.operate} onClick={event => handleShareBtn(event)}>
                                <ShareIcon />
                                分享
                            </div>
                            {isAlbum && (
                                <div className={style.operate}>
                                    <VideoLibraryIcon />
                                    集數
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={style.chatBar}>
                        <div className={style.chatBtn} onClick={event => handleChat(event)}>
                            <ChatIcon /> 開啟你與她的新話題！
                        </div>
                        <div
                            className={style.giftBtn}
                            onClick={event => handleChat(event, 'gifts')}
                        >
                            <GiftsIcon />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CpMediaInfoCard;
